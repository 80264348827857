/* @flow */

import React from 'react';

import {svgIconWrapper} from '../svg-icon-wrapper';
import Warning from './icon-warning.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <Warning {...validProps} />;
}
SvgComponent.iconName = 'warning';

export const WarningIcon = svgIconWrapper(SvgComponent);

/* @flow */

import * as React from 'react';

import classnames from 'classnames';

import './body.css';

type Props = {
    children?: React.Node,
    as?: string,
    size?: 'mini' | 'small' | 'normal' | 'large' | 'xlarge',
    color?: 'light' | 'medium' | 'dark' | 'person' | 'error' | 'blue' | 'white' | 'green',
    isBold?: boolean,
    isItalic?: boolean,
    shouldTruncate?: boolean,
    getBodyRef?: any,
    title?: string,
    underline?: boolean,
    strikethrough?: boolean,
};

export function Body(props: Props) {
    const {
        as = 'span',
        size = 'normal',
        color = 'medium',
        isBold,
        isItalic,
        underline,
        strikethrough,
        shouldTruncate,
        getBodyRef,
        title,
    } = props;

    const Tag = shouldTruncate ? 'div' : as;

    const styleNames = classnames({
        body: true,
        mini: size === 'mini',
        small: size === 'small',
        normal: size === 'normal',
        large: size === 'large',
        xlarge: size === 'xlarge',
        light: color === 'light',
        medium: color === 'medium',
        dark: color === 'dark',
        person: color === 'person',
        error: color === 'error',
        blue: color === 'blue',
        white: color === 'white',
        green: color === 'green',
        bold: isBold,
        italic: isItalic,
        underline,
        strikethrough,
        truncate: shouldTruncate,
    });

    return (
        <Tag styleName={styleNames} ref={getBodyRef} title={title}>
            {props.children}
        </Tag>
    );
}

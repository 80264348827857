/* @flow */
import * as React from 'react';
import '../../../client_modules/shells/react/components/shared-styles.css';
import {Link} from 'shells/link';

type Props = {
    submissionUrl: string,
};

export const SubmissionModalUrlSubHeader = ({submissionUrl}: Props) => {
    return (
        <div>
            {
                <div className='mt-4'>
                    Submission URL:&nbsp;
                    <Link href={submissionUrl} size='small' variant='primary' newTab={true}>
                        {submissionUrl}
                    </Link>
                </div>
            }
        </div>
    );
};

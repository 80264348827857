/* @flow */
import * as React from 'react';
import {ScrollableContainerWithShadow} from 'shells/layout';
import {TimelineBannerModalWrapper} from '../../timeline/banner/timeline-banner-modal-wrapper';
import {useGetSubmission} from './graphql/hooks/use-get-submission';
import {SubmissionData} from './submission-data';
import {SubmissionModalUrlSubHeader} from './submission-modal-url-sub-header';

import './submission-data.css';

type Props = {
    onClose: () => void,
    submitter: Object,
    form: Object,
    submissionId: string,
    submissionTime: number,
};

export function ViewFormSubmissionModal(props: Props) {
    const {submittedFields, submissionUrl, loading} = useGetSubmission(props.submissionId);
    let subtitleComponent = null;

    if (submissionUrl) {
        subtitleComponent = (
            <SubmissionModalUrlSubHeader form={props.form} submissionUrl={submissionUrl} />
        );
    }

    return (
        <>
            <TimelineBannerModalWrapper
                onClose={props.onClose}
                iconVariant='form-submitted'
                timestamp={props.submissionTime}
                actor={props.submitter}
                entryType='Submission'
                changeType='FORM_SUBMISSION_MAPPED'
                entity={props.form}
                subtitleComponent={subtitleComponent}
            >
                <ScrollableContainerWithShadow
                    direction='vertical'
                    maxContainerSize={600}
                    showReverseGradientShadow={true}
                    gradientShadowSize={60}
                >
                    <div className='pad-32'>
                        <SubmissionData submittedFields={submittedFields} isLoading={loading} />
                    </div>
                </ScrollableContainerWithShadow>
            </TimelineBannerModalWrapper>
        </>
    );
}

/* @flow */
import {useQuery} from '@apollo/react-hooks';

import type {
    GetSubmission as GetSubmissionQuery,
    GetSubmissionVariables as GetSubmissionQueryVariables,
} from 'nutshell-graphql-types';

import type {SubmittedField} from 'shells/form/view-submission-modal-content/view-submission-data';

import {getPrimaryEmailAddress} from 'nutshell-core/utils/peep/get-primary-email-address';

import {getValueForField} from '../../form-submissions-section';
import GET_SUBMISSION from '../queries/get-submission.graphql';

export function useGetSubmission(
    submissionId: string
): {
    submittedFields: SubmittedField[],
    submissionUrl: ?string,
    loading: boolean,
} {
    const {data, loading} = useQuery<GetSubmissionQuery, GetSubmissionQueryVariables>(
        GET_SUBMISSION,
        {
            variables: {id: submissionId},
            notifyOnNetworkStatusChange: true,
        }
    );

    const submission = data ? data.submission : null;

    let submittedFields = [];
    if (submission) {
        submittedFields = submission.fields.edges.map((edge) => ({
            id: edge.node.id,
            label: edge.node.label,
            isRequired: edge.node.isRequired,
            value: getValueForField(edge.fieldValue),
        }));

        if (
            submission.booking &&
            submission.booking.additionalAttendees &&
            submission.booking.additionalAttendees.length
        ) {
            submittedFields.push({
                id: 'additionalAttendees',
                label: 'Additional Attendees',
                value: submission.booking.additionalAttendees
                    .map((attendee) => attendee && getPrimaryEmailAddress(attendee.emailAddresses))
                    .join(', '),
                isRequired: false,
            });
        }
    }
    const submissionUrl = submission && submission.submissionUrl ? submission.submissionUrl : null;

    return {submittedFields, submissionUrl, loading};
}

/* @flow */

import * as React from 'react';

import type {
    EventFragmentSparse_actor as Actor,
    NoteFragment_entity as Entity,
    SubmissionFragment_formVersion_form as Form,
    EmailInteractionFragmentFull as EmailInteraction,
    EmailFragmentFull as Email,
} from 'nutshell-graphql-types';

import type {ChangeType, EventType} from 'shells/timeline/types';
import type {TimelineEntryIconVariant} from 'shells/timeline/gutter/types';
import type {TimelineHeaderProps} from 'shells/timeline/entry/header';

import {TimelineModalWrapper} from '../modals/timeline-modal-wrapper';

type Props = {|
    isLoading?: boolean,
    actor: Actor,
    entryType: EventType,
    changeType: ChangeType,
    timestamp: number,
    entity?: ?Entity | Form | EmailInteraction | Email,
    iconVariant: TimelineEntryIconVariant,
    onClose: () => void,
    children: React.Node,
    subtitleComponent?: React.Node,
    ...TimelineHeaderProps,
|};

export function TimelineBannerModalWrapper(props: Props) {
    const {
        actor,
        entryType,
        changeType,
        timestamp,
        entity,
        iconVariant,
        onClose,
        children,
        ...timelineHeaderProps
    } = props;

    return (
        <TimelineModalWrapper
            isLoading={props.isLoading}
            onClose={onClose}
            iconVariant={iconVariant}
            timestamp={timestamp}
            actor={actor}
            entryType={entryType}
            changeType={changeType}
            // We are passing a Full fragment to a component expecting a smaller Sparse
            // $FlowIgnore fragment. This won't break anything, but confuses Flow
            entity={entity}
            {...timelineHeaderProps}
        >
            {children}
        </TimelineModalWrapper>
    );
}

/* @flow */

import * as React from 'react';

import type {EventFragmentSparse} from 'nutshell-graphql-types';
import {getApiTypeFromGraphQLTypename} from 'nutshell-core/utils';
import {LinkedEntity} from 'shells/linked-entity';

import {TimelineBanner} from './timeline-banner';

import './timeline-banner.css';

type Props = {|event: EventFragmentSparse|};

export function TimelineBannerThread(props: Props) {
    const {event} = props;
    const {payload} = event;

    /**
     * We need to include this check, even though we are only passing an event
     * with a payload.__typename of 'Thread' because Apollo doesn't generate a specific
     * enough type when using a union type (like the TimelineEventPayload).
     * See this issue for more detail:
     * https://github.com/apollographql/apollo-tooling/issues/1223
     */
    if (payload.__typename === 'Thread') {
        const bestEntity = event.payload.bestEntity ? event.payload.bestEntity : undefined;
        // $FlowIgnore
        const entityType = getApiTypeFromGraphQLTypename(bestEntity ? bestEntity.__typename : '');

        let verb;
        if (event.changeType === 'ADDED') {
            verb = 'started';
        } else if (event.changeType === 'EDITED') {
            verb = 'closed';
        }

        const action = (
            <span>
                {verb} a text thread with&nbsp;
                <span styleName='target'>
                    <LinkedEntity
                        type={entityType}
                        useEntityColor={true}
                        shouldClientRoute={true}
                        allowWrap={true}
                        displayInline={true}
                        greenLeads={true}
                        {...bestEntity}
                    />
                </span>
            </span>
        );

        return (
            <TimelineBanner
                id={event.id}
                iconVariant='thread'
                timestamp={event.changeTime}
                actor={event.actor}
                action={action}
            />
        );
    }

    return <></>;
}

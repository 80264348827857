/* @flow */

import * as React from 'react';

import {HelpButton} from '../help';
import {Popover} from '../popover';

import './popover-info.css';

type Props = {
    info: string | React.Node,
    size?: number,
    variant?: 'error' | 'green',
    bodyColor?: 'light' | 'dark' | 'error',
};

PopoverInfo.defaultProps = {
    bodyColor: 'light',
};

export function PopoverInfo(props: Props) {
    const [isPopoverShown, setIsPopoverShown] = React.useState<boolean>(false);
    const [iconRef, setIconRef] = React.useState<?HTMLElement>(null);

    const {info, ...restProps} = props;

    return (
        <>
            <HelpButton
                ref={(ref) => {
                    setIconRef(ref);
                }}
                size={props.size}
                variant={props.variant}
                onClick={(e) => {
                    e.stopPropagation();
                    setIsPopoverShown(true);
                }}
            />
            {isPopoverShown ? (
                <Popover
                    {...restProps}
                    anchor={iconRef}
                    onBlur={(e) => {
                        e.stopPropagation();
                        setIsPopoverShown(false);
                    }}
                    bodyColor={props.bodyColor}
                >
                    <div
                        className='pad-16'
                        styleName='popover-text'
                        onClick={(e) => {
                            e.stopPropagation(); // Prevent click inside popover from bubbling up
                        }}
                    >
                        {info}
                    </div>
                </Popover>
            ) : (
                undefined
            )}
        </>
    );
}

/* @flow */

import * as React from 'react';

import {svgIconWrapper} from '../svg-icon-wrapper';
import CardSVG from './icon-card.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <CardSVG {...validProps} />;
}
SvgComponent.iconName = 'card';

export const CardIcon = svgIconWrapper(SvgComponent);

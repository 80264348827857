/* @flow */

import {faIconCreator} from './fa-icon-creator';

export {getFileIcon} from './get-icon';
export {NotificationIcon} from './notification-icon';

export {ButtonIcon} from './button-icon';

export {LeadStatusIcon} from './lead-status-icon';
export type {Status} from './lead-status-icon';

export {EmailSequenceIcon} from './email-sequence-icon';

// SVG
export {AccountIcon} from './svg/account-icon';
export {AccountChildOverlayIcon} from './svg/account-child-overlay-icon';
export {AccountsCircleIcon} from './svg/accounts-circle-icon';
export {AccountParentIcon} from './svg/account-parent-icon';
export {AccountParentCircleIcon} from './svg/account-parent-circle-icon';
export {ActivitiesCircleIcon} from './svg/activities-circle-icon';
export {ActivityEmptyStateIcon} from './svg/activity-empty-state-icon';
export {ActivityErrorStateIcon} from './svg/activity-error-state-icon';
export {ActivityIcon} from './svg/activity-icon';
export {ActivityImportantIcon} from './svg/activity-important-icon';
export {ActivityMinimizeIcon} from './svg/activity-minimize-icon';
export {AddIcon} from './svg/add-icon';
export {AddReactionIcon} from './svg/add-reaction-icon';
export {AdminLockIcon} from './svg/admin-lock-icon';
export {AlarmClockIcon} from './svg/alarm-clock-icon';
export {AddressCardIcon} from './svg/address-card-icon';
export {AlignCenterIcon} from './svg/align-center-icon';
export {AlignLeftIcon} from './svg/align-left-icon';
export {AlignRightIcon} from './svg/align-right-icon';
export {ArchiveIcon} from './svg/archive-icon';
export {ArrowDownIcon} from './svg/arrow-down-icon';
export {ArrowLeftIcon} from './svg/arrow-left-icon';
export {ArrowRightIcon} from './svg/arrow-right-icon';
export {ArrowsAscIcon} from './svg/arrows-asc-icon';
export {ArrowsDescIcon} from './svg/arrows-desc-icon';
export {ArrowUpDownIcon} from './svg/arrow-up-down-icon';
export {AudienceIcon} from './svg/audience-icon';
export {BookingIcon} from './svg/booking-icon';
export {BroadcastIcon} from './svg/broadcast-icon';
export {BrowserIcon} from './svg/browser-icon';
export {CalendarIcon} from './svg/calendar-icon';
export {CalendarPenIcon} from './svg/calendar-pen-icon';
export {CalendarXIcon} from './svg/calendar-x-icon';
export {CampaignIcon} from './svg/campaign-icon';
export {CancelIcon} from './svg/cancel-icon';
export {CardIcon} from './svg/card-icon';
export {ChatIcon} from './svg/chat-icon';
export {CheckboxIcon} from './svg/checkbox-icon';
export {CheckmarkIcon} from './svg/checkmark-icon';
export {CircleMinusIcon} from './svg/circle-minus-icon';
export {CircleWarningIcon} from './svg/circle-warning-icon';
export {CloneIcon} from './svg/clone-icon';
export {CloseIcon} from './svg/close-icon';
export {CogIcon} from './svg/cog-icon';
export {CollapseIcon} from './svg/collapse-icon';
export {ColumnPickerIcon} from './svg/column-picker-icon';
export {ColumnsIcon} from './svg/columns-icon';
export {CommentIcon} from './svg/comment-icon';
export {CompanyIcon} from './svg/company-icon';
export {CompanyContactIcon} from './svg/company-contact-icon';
export {CompanyTypeIcon} from './svg/company-type-icon';
export {CompassIcon} from './svg/compass-icon';
export {CompetitorIcon} from './svg/competitor-icon';
export {ComingSoonIcon} from './svg/coming-soon-icon';
export {ConfirmIcon} from './svg/confirm-icon';
export {ConstantContactIcon} from './svg/constant-contact-icon';
export {ContactAssigneeIcon} from './svg/contact-assignee-icon';
export {ContactIcon} from './svg/contact-icon';
export {ContactsCircleIcon} from './svg/contacts-circle-icon';
export {CopyIcon} from './svg/copy-icon';
export {CtcKeypadIcon} from './svg/ctc-keypad-icon';
export {DeltaArrowDownIcon} from './svg/delta-arrow-down-icon';
export {DeltaArrowUpIcon} from './svg/delta-arrow-up-icon';
export {DesktopIcon} from './svg/desktop-icon';
export {DoorClosedIcon} from './svg/door-closed-icon';
export {DoorOpenIcon} from './svg/door-open-icon';
export {DownloadIcon} from './svg/download-icon';
export {DragHandleIcon} from './svg/drag-handle-icon';
export {DripSequenceIcon} from './svg/drip-sequence-icon';
export {EmailIcon} from './svg/email-icon';
export {EmailSeriesIcon} from './svg/email-series-icon';
export {EmptyFolderIcon} from './svg/empty-folder-icon';
export {EnvelopeIcon} from './svg/envelope-icon';
export {EnvelopeOpenTextIcon} from './svg/envelope-open-text-icon';
export {ExCircleIcon} from './svg/ex-circle-icon';
export {ExpandIcon} from './svg/expand-icon';
export {ExploreIcon} from './svg/explore-icon';
export {ExternalLinkIcon} from './svg/external-link-icon';
export {EyeIcon} from './svg/eye-icon';
export {EyeSlashIcon} from './svg/eye-slash-icon';
export {FileAudioIcon} from './svg/file-audio-icon';
export {FileExcelIcon} from './svg/file-excel-icon';
export {FileIcon} from './svg/file-icon';
export {FileImageIcon} from './svg/file-image-icon';
export {FilePdfIcon} from './svg/file-pdf-icon';
export {FilePowerpointIcon} from './svg/file-powerpoint-icon';
export {FileTextIcon} from './svg/file-text-icon';
export {FileUploadIcon} from './svg/file-upload-icon';
export {FileVideoIcon} from './svg/file-video-icon';
export {FileWordIcon} from './svg/file-word-icon';
export {FileZipIcon} from './svg/file-zip-icon';
export {FilterIcon} from './svg/filter-icon';
export {FlameIcon} from './svg/flame-icon';
export {FolderIcon} from './svg/folder-icon';
export {FormPenIcon} from './svg/form-pen-icon';
export {FxIcon} from './svg/fx-icon';
export {ConnectedFormsIcon} from './svg/connected-forms-icon';
export {FrownyIcon} from './svg/frowny-icon';
export {GlossaryIcon} from './svg/glossary-icon';
export {GlossaryBookmarkIcon} from './svg/glossary-bookmark-icon';
export {GoalActivityLoggedIcon} from './svg/goal-activity-logged-icon';
export {GoalAudienceIcon} from './svg/goal-audience-icon';
export {GoalClickIcon} from './svg/goal-click-icon';
export {GoalEmailIcon} from './svg/goal-email-icon';
export {GoalLeadClosedIcon} from './svg/goal-lead-closed-icon';
export {GoalLeadCreatedIcon} from './svg/goal-lead-created-icon';
export {GoalLeadFieldsIcon} from './svg/goal-lead-fields-icon';
export {GoalLeadInfoIcon} from './svg/goal-lead-info-icon';
export {GoalLeadStageIcon} from './svg/goal-lead-stage-icon';
export {GoalLeadValueIcon} from './svg/goal-lead-value-icon';
export {GoalTagIcon} from './svg/goal-tag-icon';
export {GoogleMeetIcon} from './svg/google-meet-icon';
export {HalfAndHalfIcon} from './svg/half-and-half-icon';
export {H1Icon} from './svg/h1-icon';
export {H2Icon} from './svg/h2-icon';
export {HeaderIcon} from './svg/header-icon';
export {HorizontalChartIcon} from './svg/horizontal-chart-icon';
export {HourglassEndIcon} from './svg/hourglass-end-icon';
export {HourglassHalfIcon} from './svg/hourglass-half-icon';
export {HourglassStartIcon} from './svg/hourglass-start-icon';
export {HtmlAttachmentIcon} from './svg/html-attachment-icon';
export {HtmlBoldIcon} from './svg/html-bold-icon';
export {HtmlImageIcon} from './svg/html-image-icon';
export {HtmlItalicIcon} from './svg/html-italic-icon';
export {HtmlLinkIcon} from './svg/html-link-icon';
export {HtmlListIcon} from './svg/html-list-icon';
export {HtmlUnderlineIcon} from './svg/html-underline-icon';
export {ImportIcon} from './svg/icon-import';
export {InboundArrowIcon} from './svg/inbound-arrow-icon';
export {InboundCallIcon} from './svg/inbound-call-icon';
export {InboxIcon} from './svg/inbox-icon';
export {IndustryIcon} from './svg/industry-icon';
export {InfoIcon} from './svg/info-icon';
export {ReportsIcon} from './svg/reports-icon';
export {LeadHotIcon} from './svg/lead-hot-icon';
export {LeadIconChevron} from './svg/lead-icon-chevron';
export {LeadIcon} from './svg/lead-icon';
export {LeadCancelledIcon} from './svg/lead-cancelled-icon';
export {LeadLostIcon} from './svg/lead-lost-icon';
export {LeadPendingIcon} from './svg/lead-pending-icon';
export {LeadsCircleIcon} from './svg/leads-circle-icon';
export {LightbulbBrightIcon} from './svg/lightbulb-bright-icon';
export {LightbulbDimIcon} from './svg/lightbulb-dim-icon';
export {LineVerticalIcon} from './svg/line-vertical-icon';
export {LinkClickIcon} from './svg/link-click-icon';
export {LinkedInIcon} from './svg/linked-in-icon';
export {ListIcon} from './svg/list-icon';
export {LoadingIcon} from './svg/loading-icon';
export {LocationPinIcon} from './svg/location-pin-icon';
export {LockedIcon} from './svg/locked-icon';
export {LongTextIcon} from './svg/long-text-icon';
export {MagicIcon} from './svg/magic-icon';
export {MagnifyingGlassDollarIcon} from './svg/magnifying-glass-dollar-icon';
export {MagnifyingGlassChartIcon} from './svg/magnifying-glass-chart-icon';
export {MailchimpIcon} from './svg/mailchimp-icon';
export {MapPinIcon} from './svg/map-pin-icon';
export {MemoIcon} from './svg/memo-icon';
export {MeetingIcon} from './svg/meeting-icon';
export {MessagesDollarIcon} from './svg/messages-dollar-icon';
export {MessagesIcon} from './svg/messages-icon';
export {MessageIcon} from './svg/message-icon';
export {MessageIconSolid} from './svg/message-icon-solid';
export {MicrophoneIcon} from './svg/microphone-icon';
export {MicrosoftTeamsIcon} from './svg/microsoft-teams-icon';
export {MobilePhoneIcon} from './svg/mobile-phone-icon';
export {MultiplePipelineIcon} from './svg/multiple-pipeline-icon';
export {NameIcon} from './svg/name-icon';
export {NextStepIcon} from './svg/next-step-icon';
export {NewsletterIcon} from './svg/newsletter-icon';
export {NewTimelineIcon} from './svg/new-timeline-icon';
export {NoteIcon} from './svg/note-icon';
export {NutshellLogoMarkIcon} from './svg/nutshell-logo-mark-icon';
export {NutshellSpookyLogoMarkIcon} from './svg/nutshell-spooky-logo-mark-icon';
export {OnDemandReportsIcon} from './svg/on-demand-reports-icon';
export {OpenIcon} from './svg/open-icon';
export {OutboundArrowIcon} from './svg/outbound-arrow-icon';
export {OutboundCallIcon} from './svg/outbound-call-icon';
export {OutreachIcon} from './svg/outreach-icon';
export {PandadocIcon} from './svg/pandadoc-icon';
export {PauseCircleIcon} from './svg/pause-circle-icon';
export {PauseIcon} from './svg/pause-icon';
export {PersonCheckIcon} from './svg/person-check-icon';
export {PhoneCallIcon} from './svg/phone-call-icon';
export {PinIcon} from './svg/pin-icon';
export {CellPhoneIcon} from './svg/cell-phone-icon';
export {PlantBigFilledIcon} from './svg/plant-big-filled-icon';
export {PlantBigOutlinedIcon} from './svg/plant-big-outlined-icon';
export {PlantMediumFilledIcon} from './svg/plant-medium-filled-icon';
export {PlantMediumOutlinedIcon} from './svg/plant-medium-outlined-icon';
export {PlantSmallFilledIcon} from './svg/plant-small-filled-icon';
export {PlantSmallOutlinedIcon} from './svg/plant-small-outlined-icon';
export {PlayButtonIcon} from './svg/play-button-icon';
export {PlusThinIcon} from './svg/plus-thin-icon';
export {ProBadgeIcon} from './svg/pro-badge';
export {ProductIcon} from './svg/product-icon';
export {ProspectorIcon} from './svg/prospector-icon';
export {QRCodeIcon} from './svg/qr-code-icon';
export {QuotaReachedIcon} from './svg/quota-reached-icon';
export {RadioIcon} from './svg/radio-icon';
export {RecurringIcon} from './svg/recurring-icon';
export {ReplyClockIcon} from './svg/reply-clock-icon';
export {ReplyIcon} from './svg/reply-icon';
export {ReplyAllIcon} from './svg/reply-all-icon';
export {RetiringIcon} from './svg/retiring-icon';
export {RetryIcon} from './svg/retry-icon';
export {RevenueIcon} from './svg/revenue-icon';
export {RowsIcon} from './svg/rows-icon';
export {SavingIcon} from './svg/saving-icon';
export {SchedulerIcon} from './svg/scheduler-icon';
export {SentEmailIcon} from './svg/sent-email-icon';
export {ShortTextIcon} from './svg/short-text-icon';
export {SidebarIconAccountsIcon} from './svg/sidebar-icon-accounts';
export {SidebarIconContactsIcon} from './svg/sidebar-icon-contacts';
export {SidebarIconDashboardIcon} from './svg/sidebar-icon-dashboard';
export {SidebarIconHelpIcon} from './svg/sidebar-icon-help';
export {SidebarIconLogoutIcon} from './svg/sidebar-icon-logout';
export {SidebarIconNotificationsIcon} from './svg/sidebar-icon-notifications';
export {SidebarIconOnboardingIcon} from './svg/sidebar-icon-onboarding';
export {SidebarIconReportsIcon} from './svg/sidebar-icon-reports';
export {SidebarIconReportsIconOld} from './svg/sidebar-icon-reports-old';
export {SidebarIconSearchIcon} from './svg/sidebar-icon-search';
export {SidebarIconSetupIcon} from './svg/sidebar-icon-setup';
export {SiteGlobeIcon} from './svg/site-globe-icon';
export {SlackIcon} from './svg/slack-icon';
export {SolidClockIcon} from './svg/solid-clock-icon';
export {SolidMessageCircleIcon} from './svg/solid-message-circle-icon';
export {SortIcon} from './svg/sort-icon';
export {SourceIcon} from './svg/source-icon';
export {SparkleIcon} from './svg/sparkle-icon';
export {SparkleOpenIcon} from './svg/sparkle-open-icon';
export {StageIcon} from './svg/stage-icon';
export {StepIcon} from './svg/step-icon';
export {StopCircleIcon} from './svg/stop-circle-icon';
export {StopIcon} from './svg/stop-icon';
export {SubheaderIcon} from './svg/subheader-icon';
export {SuccessMessageIcon} from './svg/success-message-icon';
export {TabletIcon} from './svg/tablet-icon';
export {TagIcon} from './svg/tag-icon';
export {TagsIcon} from './svg/tags-icon';
export {TaskIcon} from './svg/task-icon';
export {TeamIcon} from './svg/team-icon';
export {TemplateIcon} from './svg/template-icon';
export {TerritoryIcon} from './svg/territory-icon';
export {TextBlockIcon} from './svg/text-block-icon';
export {TextIcon} from './svg/text-icon';
export {ThumbsDownIcon} from './svg/thumbs-down-icon';
export {ThumbsUpIcon} from './svg/thumbs-up-icon';
export {TickCircleIcon} from './svg/tick-circle-icon';
export {TickCircleOutlineIcon} from './svg/tick-circle-outline-icon';
export {TicketIcon} from './svg/ticket-icon';
export {TodoEmptyStateIcon} from './svg/todo-empty-state-icon';
export {TodoErrorStateIcon} from './svg/todo-error-state-icon';
export {TrashEmptyIcon} from './svg/trash-empty-icon';
export {TrashIcon} from './svg/trash-icon';
export {TVRetroIcon} from './svg/tv-retro-icon';
export {UnlockIcon} from './svg/unlock-icon';
export {UnloggedIcon} from './svg/unlogged-icon';
export {UnpinIcon} from './svg/unpin-icon';
export {UpgradeArrowIcon} from './svg/upgrade-arrow-icon';
export {UserIcon} from './svg/user-icon';
export {UserGroupIcon} from './svg/user-group-icon';
export {VirtualMeetingIcon} from './svg/virtual-meeting-icon';
export {VisitorIqIcon} from './svg/visitor-iq-icon';
export {VoicemailIcon} from './svg/voicemail-icon';
export {WarningIcon} from './svg/warning-icon';
export {WebFormIcon} from './svg/web-form-icon';
export {ZoomIcon} from './svg/zoom-icon';
export {ZoomTextIcon} from './svg/zoom-text-icon';

// Font Awesome Icons
export const AsteriskIcon = faIconCreator('asterisk');
export const BoltIcon = faIconCreator('bolt');
export const CaretDown = faIconCreator('caret-down');
export const CaretRight = faIconCreator('caret-right');
export const CaretUp = faIconCreator('caret-up');
export const CheckIcon = faIconCreator('check');
export const ChevronDownIcon = faIconCreator('chevron-down');
export const ChevronLeftIcon = faIconCreator('chevron-left');
export const ChevronRightIcon = faIconCreator('chevron-right');
export const ChevronUpIcon = faIconCreator('chevron-up');
export const ClockIcon = faIconCreator('clock-o');
export const CloudDownloadIcon = faIconCreator('cloud-download');
export const CloudUploadIcon = faIconCreator('cloud-upload');
export const CogsIcon = faIconCreator('cogs');
export const CreditCardIcon = faIconCreator('credit-card');
export const DescriptionIcon = faIconCreator('align-left');
export const DollarSymbolIcon = faIconCreator('usd');
export const EditIcon = faIconCreator('edit');
export const ErrorCircleIcon = faIconCreator('exclamation-circle');
export const ErrorTriangleIcon = faIconCreator('exclamation-triangle');
export const FrownIcon = faIconCreator('frown-o');
export const GlobeIcon = faIconCreator('globe');
export const HashtagIcon = faIconCreator('hashtag');
export const InfoCircleIcon = faIconCreator('info-circle');
export const KeyIcon = faIconCreator('key');
export const LockIcon = faIconCreator('lock');
export const MicrophoneSlashIcon = faIconCreator('microphone-slash');
export const MinimizeIcon = faIconCreator('window-minimize');
export const MinusCircleIcon = faIconCreator('minus-circle');
export const PaperPlane = faIconCreator('paper-plane');
export const PaperclipIcon = faIconCreator('paperclip');
export const Pencil = faIconCreator('pencil');
export const PhoneIcon = faIconCreator('phone');
export const PlayIcon = faIconCreator('play');
export const PlusIcon = faIconCreator('plus');
export const RefreshIcon = faIconCreator('refresh');
export const RepeatIcon = faIconCreator('repeat');
export const RocketIcon = faIconCreator('rocket');
export const SearchIcon = faIconCreator('search');
export const ShareIcon = faIconCreator('share');
export const SignOutIcon = faIconCreator('sign-out');
export const SpinnerIcon = faIconCreator('spinner fa-spin');
export const StarIcon = faIconCreator('star');
export const TimesCircleIcon = faIconCreator('times-circle');
export const TimesIcon = faIconCreator('times');
export const UsersIcon = faIconCreator('users');
export const WandIcon = faIconCreator('magic');

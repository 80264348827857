/* @flow */
import * as React from 'react';

import {InfoIcon} from '../icon';

import './help-styles.css';

type Props = {
    onClick: (e: SyntheticMouseEvent<>) => void,
    variant?: 'error' | 'green',
    size?: number,
};

export const HelpButton = React.forwardRef<Props, HTMLButtonElement>((props: Props, ref) => {
    const getStyleName = () => {
        if (props.variant === 'error') {
            return 'help-icon--error';
        }

        if (props.variant === 'green') {
            return 'help-icon--green';
        }

        return 'help-icon';
    };

    return (
        <button styleName={getStyleName()} type='button' onClick={props.onClick} ref={ref}>
            <InfoIcon
                wrapWithDiv={false}
                size={props.size ? props.size : 16}
                useCurrentColor={true}
            />
        </button>
    );
});

/* @flow */

import * as React from 'react';
import classnames from 'classnames';

import {ButtonCard} from './button-card';
import type {ControlOption} from './segmented-control';

import './segmented-control.css';

type Props = {
    option: ControlOption,
    isSelected: boolean,
    setSelectedValue: (value: any) => void,
    isDisabled?: boolean,
    onChange?: (value: any) => void,
};

export function SegmentedControlButton({option, isSelected, setSelectedValue, ...props}: Props) {
    const containerStyle = classnames('button-content', {
        'button-content--small': option.type === 'small',
        'button-content--large': option.type === 'large',
    });

    const titleStyle = classnames('title', {
        'title--large': option.type === 'large',
        'title--selected': isSelected,
    });

    return (
        <ButtonCard
            isSelected={isSelected}
            onClick={() => {
                setSelectedValue(option.value);
                props.onChange && props.onChange(option.value);
            }}
            isTransparent={!isSelected}
            isFlex={true}
            isDisabled={props.isDisabled}
            fitHeight={true}
        >
            <div styleName={containerStyle}>
                <div styleName={titleStyle}>{option.title}</div>
                {option.subject && <div styleName='inner-text'>{option.subject}</div>}
                {option.content && (
                    <div className='truncate' styleName='inner-text'>
                        {option.content}
                    </div>
                )}
            </div>
        </ButtonCard>
    );
}

/* @flow */

import * as React from 'react';

import {LoadingPage} from 'shells/loading-page';
import {ViewSubmissionData} from 'shells/form/view-submission-modal-content/view-submission-data';
import type {SubmittedField} from 'shells/form/view-submission-modal-content/view-submission-data';

import './submission-data.css';

type Props = {
    submittedFields: SubmittedField[],
    isLoading: boolean,
};

export function SubmissionData(props: Props) {
    const {submittedFields, isLoading} = props;

    return (
        <>
            {isLoading ? <LoadingPage /> : undefined}
            <ViewSubmissionData submittedFields={submittedFields} />
        </>
    );
}
